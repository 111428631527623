import { PatientType } from "src/app/models/patient";
import { IcdCodesSchema } from "src/app/react/reactStore/reactSlice/icdNotesSlice";
import {
  PatientTypes,
  UserRoles,
} from "src/app/shared/accessControl/roleInterface";

export interface Addendum {
  name?: string;
  note: string;
  timestamp?: Date;
  uniqueID?: {
    name: string;
    email: string;
  };
}

export type NoteTypeCriteria = {
  userRole: UserRoles | string;
  patientType: PatientTypes;
};

export interface NoteFormsDataModel {
  noteType: string;
  subTypes: SubTypeForm[];
}

export type SubTypeForm = {
  noteSubType: string;
  isSigned: boolean;
  isPend: boolean;
};

export interface RequestModal {
  author: string;
  role: string;
  uniqueID: {
    name: string;
    email: string;
  };
}

export enum CosignStatusType {
  Accepted = "accepted",
  Rejected = "rejected",
  Pending = "pending",
  Optional = "optional",
}
export interface Cosign {
  status?: CosignStatusType;
  requestedBy?: RequestModal;
  requestedTo?: RequestModal;
  physicianMessage?: string;
  attestedOn?: Date;
}

export interface Component {
  displayName: string;
  type: ComponentType;
  value: string;
  id: string;
}

export enum ComponentType {
  Editor = "editor",
  Chips = "chips",
}

export class NoteContent {
  refId?: string;
  noteType?: string;
  noteSubType?: string;
  components: Component[];
  addendum?: Addendum[];
  pendOrSigned?: SubmitTypes;
  icdCodes?: IcdCodesSchema[];
}

export interface Intensivist {
  name: string;
  email: string;
  author: string;
  status?: string;
  physicianMessage?: string;
}

export type NoteForms = {
  noteType: string;
  noteSubType: string;
};

export class NoteForm {
  components: Component[];
  addendum: Addendum[];
  refId: string;
  noteType: string;
  noteSubType: string;
  isDeleteEnabled?: boolean;
  lock?: { name: string; uniqueID: string };
  impactCase: boolean;
  chargeable: boolean;
  cosign: Cosign;
}

export enum CTAType {
  New = "new",
  Edit = "edit",
  Addendum = "addendum",
  Attest = "attest",
  EditAttest = "edit attested note",
  ActionComplete = "all action complete",
}

export enum SubmitTypes {
  Sign = "signed",
  Pend = "pended",
}

export enum API {
  Update,
  Create,
}

export interface NoteTemplatesResponse {
  templates: NoteFormsDataModel[];
  permissions: NotePermissions[];
}

export type ActionType = "sign" | "pend";

export interface NotePermissions {
  [category: string]: {
    [templateName: string]: {
      actions: {
        action: ActionType; // Constrained to "sign" or "pend"
      }[];
      patientTypes: PatientType[];
    };
  };
}
