<div id="notesInput">
  <form class="note" [formGroup]="patientNotesForm">
    <div class="note-main">
      <div class="note-main__left-sec">
        <div class="note-main__left-sec--type">
          <mat-form-field [floatLabel]="'always'">
            <mat-select class="select-input" formControlName="noteType">
              <mat-option [value]="defaultNoteType" disabled>{{
                defaultNoteType
              }}</mat-option>
              <mat-option
                (click)="onSelectNoteType(formNoteType)"
                *ngFor="let formNoteType of noteType$ | async"
                [value]="formNoteType"
                >{{ utils.getNoteTypeText(formNoteType, unitType) }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="note-main__left-sec--subtype">
          <mat-form-field
            *ngIf="noteSubtypeLists$ | async as noteSubtypes"
            [floatLabel]="'always'"
          >
            <mat-select class="select-input" formControlName="noteSubType">
              <mat-option
                (click)="onSelectNoteSubType(formNoteSubType.noteSubType)"
                *ngFor="let formNoteSubType of noteSubtypes.subTypes"
                [value]="formNoteSubType.noteSubType"
              >
                {{ formNoteSubType.noteSubType }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <app-loader
      [buttonLoader]="true"
      *ngIf="getLoaderState$ | async"
    ></app-loader>
    <div class="cp-flex-container" *ngIf="noteCTA$ | async as cta">
      <ng-container [ngSwitch]="cta">
        <div *ngSwitchCase="CTAType.Addendum" class="cp-flex-container">
          <cp-note-addendum
            [patientNotesForm]="patientNotesForm"
          ></cp-note-addendum>
        </div>
        <div *ngSwitchCase="CTAType.Attest" class="cp-flex-container">
          <cp-note-attest
            [patientNotesForm]="patientNotesForm"
          ></cp-note-attest>
        </div>
        <div *ngSwitchDefault class="cp-flex-container">
          <cp-note-editor
            *ngIf="!(isInitialState$ | async)"
            [patientNotesForm]="patientNotesForm"
            [isHospitalNoteChargeable]="isHospitalNoteChargeable"
          ></cp-note-editor>
        </div>
      </ng-container>
    </div>
  </form>
</div>
