export enum NoteTypelabels {
  Signed = "Signed",
  Pended = "Pended",
  Draft = "Draft",
}

export const NotesColumnsDisplayElements = {
  commonColumns: ["name", "specialty", "note_type", "last_edited"],
  types: {
    [NoteTypelabels.Draft]: ["#"],
    [NoteTypelabels.Signed]: [
      "date_created",
      "coSignStatus",
      "coSignBy",
      "impact",
      "chargeable",
      "#",
    ],
    [NoteTypelabels.Pended]: ["date_created", "impact", "chargeable", "#"],
  },
};
