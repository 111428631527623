import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { EMPTY } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { NotesService } from "src/app/services/notes.service";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import * as notesActions from "../actions/patient-chart/notes/notes.actions";

@Injectable()
export class NotesEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private notesService: NotesService,
    private _location: Location,
    private _route: Router
  ) {}

  getNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notesActions.getNotes),
      switchMap(({ CPMRN, encounters, currUser }) =>
        this.notesService.getNote(CPMRN, encounters, currUser).pipe(
          map((notes) => {
            return notesActions.setNotes({ notes });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  selectedNotesTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notesActions.highlightIncompleteConsent),
        withLatestFrom(
          this.store.pipe(select(fromPatientHeaderReducers.getPatHeaderData))
        ),
        tap(([tabDetails, patientInfo]) => {
          const { CPMRN, encounters } = patientInfo;
          if (
            !new RegExp("^/patient/.+/[0-9]+/notes").test(
              this._location.path(false)
            ) &&
            !tabDetails.isInitialState
          ) {
            this._route.navigateByUrl(`/patient/${CPMRN}/${encounters}/notes`);
          }
        })
      ),
    { dispatch: false }
  );
}
