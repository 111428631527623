import { createSelector } from "@ngrx/store";
import * as fromNotesState from "../reducers";

const getNotePermission = createSelector(
  fromNotesState.getNoteState,
  (state) => state.notePermissions
);

export const getSignPermissionForNoteTypeAndSubtype = (
  noteType: string,
  noteSubtypeType: string
) =>
  createSelector(getNotePermission, (permissions) => {
    // Check if permissions and the provided noteType and noteSubtypeType exist
    const category = permissions?.[noteType];
    const template = category?.[noteSubtypeType];
    // Return whether the 'sign' action exists in the actions array
    return !!template?.actions.find((action) => action.action === "sign");
  });
