import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class recalculateData {
  public transferDatas = new BehaviorSubject<Object>({});
  transferData = this.transferDatas.asObservable();

  constructor() {}
  transferredData(Data) {
    this.transferDatas.next(Data);
  }
}
