export namespace selectOption {
  export class selectOption<X, Y, Z> {
    text: X;
    value: Y;
    id: Z;
    selected: boolean;
    index: number;
    extraPayload: Object;

    constructor(
      text: X,
      value: Y,
      id: Z,
      selected: boolean,
      index: number,
      extraPayload?: Object
    ) {
      this.text = text;
      this.value = value;
      this.id = id;
      this.selected = selected;
      this.index = index;
      this.extraPayload = extraPayload ?? {};
    }
  }
}
