import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import Quill from "quill";

// Define the custom blot
@Directive({
  selector: "[cpQuillInit]",
})
export class QuillInitDirective implements OnInit {
  @Input("hideToolBar") hideToolBar = false;
  private _el: ElementRef = inject(ElementRef);
  quill: Quill | undefined = undefined;

  @Input("isToolBar") isToolBar: boolean = true;
  @Input() toolbar!: HTMLElement;

  @Output() onContentChanged = new EventEmitter<string>();
  @Output() onEditorCreated = new EventEmitter<Quill>();
  @Output() onFocus = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.createQuillEditor();
  }

  createQuillEditor() {
    let quillConfig: { toolbar: string | boolean | HTMLElement } = {
      toolbar: this.toolbar,
    };

    if (this.hideToolBar) {
      quillConfig = {
        toolbar: false,
      };
    }
    this.quill = new Quill(this._el.nativeElement, {
      theme: "snow",
      modules: quillConfig,
    });

    this.quill.on("text-change", (delta: any, oldDelta: any, source: any) => {
      this.onContentChanged.emit(source);
    });

    this.quill.on("selection-change", (range) => {
      if (!range) {
        this.onFocus.emit(false);
      } else {
        this.onFocus.emit(true);
      }
    });
    this.onEditorCreated.emit(this.quill);
  }
}
