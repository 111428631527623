import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShortNamePipe } from "./short-name.pipe";

const pipe = [ShortNamePipe];

@NgModule({
  declarations: pipe,
  imports: [CommonModule],
  exports: pipe,
  providers: pipe,
})
export class ShortNameModule {}
