import { createAction, props } from "@ngrx/store";
import {
  NoteFormsDataModel,
  NotePermissions,
  NoteTypeCriteria,
} from "@notes/models/notes.model";

export const LOAD_NOTE_PERMISSIONS =
  "[NOTE-INPUT-FORM-DATA] load note types permission";

export const loadNotePermissions = createAction(
  LOAD_NOTE_PERMISSIONS,
  props<{ permissions: NotePermissions }>()
);
