import React, { useState, useEffect } from 'react';
import { version as staticVersion } from 'src/version/version';

interface RefreshModalProps {
  version: string;
}

const RefreshModal: React.FC<RefreshModalProps> = ({ version }) => {
  const [isOpen, setIsOpen] = useState(false); 

  useEffect(() => {
    if (version && version !== staticVersion.no) {
      setIsOpen(true);
    } else {
      setIsOpen(false); 
    }
  }, [version]); 

  const handleHardRefresh = () => {
    if ('caches' in window) {
      caches.keys()
        .then((cacheNames) => {
          return Promise.all(cacheNames.map((name) => caches.delete(name)));
        })
        .then(() => {
          (window as Window).location.href = window.location.origin + window.location.pathname + `?t=${new Date().getTime()}`;
        })
        .catch((error) => {
          console.error('Error clearing cache:', error);
        });
    } else {
      console.log('Cache API is not available in this browser.');
    }
  };   
  
  const modalRefreshContainer: React.CSSProperties = {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    width: '28%',
    backgroundColor: '#2B2A3A',
    color: 'white',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    zIndex: 999,
  };

  const modalRefreshIcon: React.CSSProperties = {
    width: '18px',
    height: '18px',
    marginRight: '0.3rem',
  };

  const modalRefreshHeading: React.CSSProperties = {
    fontSize: '0.875rem',
    fontWeight: '600',
    color: 'white',
    marginRight: '0.5rem',
  };

  const modalRefreshText: React.CSSProperties = {
    fontSize: '0.875rem',
    marginRight: '1rem',
  };

  const modalRefreshButton: React.CSSProperties = {
    fontSize: '0.875rem',
    color: '#7FCECE',
    fontWeight: '500',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  };

  return (
    <>
      {isOpen && (
        <div style={modalRefreshContainer}>
          <div>
            <img
              src="assets/icons/Icon.svg"
              alt="update"
              style={modalRefreshIcon}
            />
          </div>
          <div style={modalRefreshHeading}>Update Available</div>
          <div style={modalRefreshText}>A new version of RADAR is available.</div>

          <button
            onClick={handleHardRefresh}
            style={modalRefreshButton}
          >
            Update
          </button>
        </div>
      )}
    </>
  );
};

export default RefreshModal;
