import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as FromPermissionActions from "../actions/note-permission-access.actions";
import { NoteFormsDataModel, NotePermissions } from "@notes/models/notes.model";

export const initialState: NotePermissions = null;
const _notePermissionReducer = createReducer(
  initialState,
  on(FromPermissionActions.loadNotePermissions, (state, { permissions }) => ({
    ...state,
    ...permissions,
  }))
);

export function reducer(state: NotePermissions, action: Action) {
  return _notePermissionReducer(state, action);
}
