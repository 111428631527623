<footer class="myFooter small">
  <div
    [ngClass]="{ 'bg-non-prod': !environment.production }"
    class="footer-container"
  >
    &copy; {{ currentYear }} Cloudphysician Healthcare Pvt. Ltd. All rights
    reserved.
  </div>

  <div class="version-no">{{ versionNumber }}</div>
</footer>
