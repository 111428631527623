import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortName",
})
export class ShortNamePipe implements PipeTransform {
  transform(name: string): string {
    const splittedName: string[] = (name?.split(" ") || []).filter(
      (val) => val
    );
    let shortName = "";

    if (splittedName.length >= 1) {
      shortName += splittedName[0][0].toUpperCase();
    }

    if (splittedName.length > 1) {
      shortName += splittedName[splittedName.length - 1][0].toUpperCase();
    }
    return shortName;
  }
}
