import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NoteContent } from "@notes/models/notes.model";
import { environment } from "src/environments/environment";

export interface IcdCodesSchema {
  description: string;
  code: string;
}

interface IcdNotesState {
  icdCodes: IcdCodesSchema[];
  loading: boolean;
  error: string | null;
  icdShowStatusState: boolean;
}

const initialState: IcdNotesState = {
  icdCodes: [],
  loading: false,
  error: null,
  icdShowStatusState: true,
};

const env = environment;

export const fetchICDCodes = createAsyncThunk(
  "icdNotes/fetchICDCodes",
  async ({
    CPMRN,
    encounters,
    noteData,
  }: {
    CPMRN: any;
    encounters: any;
    noteData: NoteContent;
  }) => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${env.apiUrl}notes/prepopulate/${CPMRN}/${encounters}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: "ICD10",
          data: { note: noteData },
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch ICD codes: ${response.statusText}`);
    }

    const data = await response.json();
    return data.data.ICD10.map(
      (item: { description: string; code: string }) => ({
        description: item.description,
        code: item.code,
      })
    );
  }
);

const icdNotesSlice = createSlice({
  name: "icdCodes",
  initialState,
  reducers: {
    addSelectedTerms: (state, { payload }) => {
      console.log(payload);
      state.icdCodes = payload || [];
    },
    updateTermsList: (state, action) => {
      const newTerms = action.payload as IcdCodesSchema[];
      state.icdCodes = [...state.icdCodes, ...newTerms];
    },
    removeTerm: (state, action) => {
      state.icdCodes = state.icdCodes.filter(
        (term) => term.code !== action.payload
      );
    },
    icdShowStatusState: (state, action) => {
      state.icdShowStatusState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchICDCodes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchICDCodes.fulfilled, (state, action) => {
        state.icdCodes = action.payload;
        state.loading = false;
      })
      .addCase(fetchICDCodes.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch ICD codes.";
        state.loading = false;
      });
  },
});

export const {
  addSelectedTerms,
  updateTermsList,
  removeTerm,
  icdShowStatusState,
} = icdNotesSlice.actions;

export default icdNotesSlice.reducer;
