import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import moment from "moment";
import { Documents } from "../../labs-scans/models/labs-scans";
import * as FromLabsActions from "../actions/lab-scan-documents.actions";

export interface DocumentsState extends EntityState<Documents> {
  spotLightRecords: string[];
}

export const documentAdaptor = createEntityAdapter<Documents>({
  sortComparer: sortByDate,
  selectId: selectDocumentId,
});

export const initialState: DocumentsState = documentAdaptor.getInitialState({
  spotLightRecords: [],
});

export function sortByDate(a: Documents, b: Documents): number {
  return moment(b.reportedAt).diff(moment(a.reportedAt));
}

export function selectDocumentId(a: Documents): string {
  return a._id;
}

const _documentsReducer = createReducer(
  initialState,
  on(FromLabsActions.loadPostsSuccess, (state, action) => {
    // Modify the documents to ensure 'classifications' is never empty
    const updatedDocuments = action.documents.map((doc) => ({
      ...doc,
      classifications:
        doc.classifications.length === 0 ? ["Others"] : doc.classifications,
    }));
    return documentAdaptor.setAll(updatedDocuments, {
      ...state,
    });
  }),

  on(FromLabsActions.loadSpotLightRecords, (state, action) => ({
    ...state,
    spotLightRecords: action.spotlight,
  }))
);

export function reducer(state, action) {
  return _documentsReducer(state, action);
}
