import { createAction, props } from "@ngrx/store";

export const logout = createAction("[Root Actions] logout user");

export const patientChartReset = createAction(
  "[Root Actions] patient chart closed"
);

export const displaySuccessMessage = createAction(
  "Display Success Message",
  props<{ message: string; description?: string }>()
);

export const displayErrorMessage = createAction(
  "Display Error Message",
  props<{ message: string; description?: string }>()
);

export const emptyAction = createAction("Empty Action");

export const crossComponentComm = createAction("[Root Actions] ");
