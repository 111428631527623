import React, { useState, useEffect, useRef } from "react";

interface ImageModalProps {
  CPMRN: string;
  imageSrc: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ CPMRN, imageSrc }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [origin, setOrigin] = useState({ x: "50%", y: "50%" });
  const imageRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleZoom = (zoomIn: boolean, zoomCenter?: { x: number; y: number }) => {
    setScale(prevScale => (zoomIn ? prevScale + 0.1 : Math.max(prevScale - 0.1, 1)));

    if (zoomCenter) {
      const containerRect = containerRef.current?.getBoundingClientRect();
      if (containerRect) {
        const zoomX = ((zoomCenter.x - containerRect.left) / containerRect.width) * 100;
        const zoomY = ((zoomCenter.y - containerRect.top) / containerRect.height) * 100;
        setOrigin({ x: `${zoomX}%`, y: `${zoomY}%` });
      }
    }
  };

  const handleReset = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
    setOrigin({ x: "50%", y: "50%" });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsPanning(true);
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (containerRect) {
      setStartPos({
        x: e.clientX - position.x - containerRect.left,
        y: e.clientY - position.y - containerRect.top
      });
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isPanning) return;
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (containerRect) {
      setPosition({
        x: e.clientX - startPos.x - containerRect.left,
        y: e.clientY - startPos.y - containerRect.top
      });
    }
  };

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    const zoomIn = e.deltaY < 0;
    handleZoom(zoomIn, { x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    const stopPanning = () => setIsPanning(false);
    document.addEventListener('mouseup', stopPanning);
    document.addEventListener('mouseleave', stopPanning);

    const container = containerRef.current;
    if (container) {
      container.addEventListener('wheel', handleWheel);
    }

    return () => {
      document.removeEventListener('mouseup', stopPanning);
      document.removeEventListener('mouseleave', stopPanning);
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    isModalOpen && (
      <div
        style={{
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: "55rem",
          height: "42rem",
          backgroundColor: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-60%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 500,
        }}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              padding: "1rem",
            }}
          >
            <button
              type="button"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => handleZoom(true)}
              aria-label="Zoom In"
            >
              <img src="assets/icons/zoom.svg" alt="Zoom In" />
            </button>

            <button
              type="button"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "none",
              }}
              aria-label="Zoom Out"
              onClick={() => handleZoom(false)}
            >
              <img src="assets/icons/zoomOut.svg" alt="Zoom Out" />
            </button>
            <button onClick={handleReset}
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "none",
              }}>
              <img src="assets/icons/reset.svg" alt="Reset" />
            </button>

            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              style={{
                width: "1.5rem",
                height: "1.5rem",
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                border: "none",
              }}
              aria-label="Close modal"
            >
              <img src="assets/icons/close.svg" alt="Close" />
            </button>
          </div>

          <div
            style={{
              borderBottom: "1px solid #CDD7EB",
              width: "100%",
            }}
          ></div>
        </div>

        <div
          ref={containerRef}
          className="image-container"
          onMouseDown={handleMouseDown}
          style={{
            background: "white",
            position: "relative",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            padding: "1rem",
            cursor: isPanning ? 'grabbing' : 'grab',
          }}
        >
          <img
            ref={imageRef}
            src={imageSrc}
            alt={`Image for CPMRN ${CPMRN}`}
            style={{
              transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
              transformOrigin: `${origin.x} ${origin.y}`, // Dynamically set origin for zooming
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }} />
        </div>
      </div>
    )
  );
};

export default ImageModal;
