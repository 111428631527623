import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CodeblueComponent } from "./codeblue/codeblue.component";
import { CodeblueSenderComponent } from "./codeblue-sender/codeblue-sender.component";
import { CodeblueCancelComponent } from "./codeblue-cancel/codeblue-cancel.component";
import { AssignedUsersModule } from "@shared-modules/features/assigned-users";
import { MeetLinkModule } from "@shared-modules/components/meet-link";

@NgModule({
  declarations: [
    CodeblueComponent,
    CodeblueSenderComponent,
    CodeblueCancelComponent,
  ],
  imports: [
    CommonModule,
    AssignedUsersModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonToggleModule,
    MeetLinkModule,
  ],
  providers: [],
  exports: [CodeblueComponent],
})
export class CodeblueModule {}
