import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, Event, NavigationEnd } from "@angular/router";
import { filter, distinctUntilChanged } from "rxjs/operators";
import { Store, select } from "@ngrx/store";

import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { PatientService } from "src/app/services/patient.service";
import {
  faBackward,
  faCircle,
  faExchangeAlt,
  faFileMedicalAlt,
  faFileUpload,
  faHeartbeat,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { faClipboard, faListAlt } from "@fortawesome/free-regular-svg-icons";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "src/app/iris-components/avatar/avatar.component";
import { displayAge } from "src/app/support-functions/calculateAge";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { TrendsComponent } from "src/app/shared-modules/features/trends/components/old-trends/trends.component";
import {
  TrendType,
  weightChartXAxisRanges,
  weightChartYAxisRanges,
} from "src/app/shared-modules/features/trends/constants/trends.data";
import { FormsService } from "src/app/admit-form/services/forms.service";
import * as fromPatientFormActions from "../../store/actions/patient-chart/patient-header/patient-form.actions";
import moment from "moment-timezone";
import * as fromViewNoteStore from "@notes-view/store";
import { Patient, PatientType } from "src/app/models/patient";
import {
  calculateCurrentWeight,
  calculateWeight,
  CALCULATE_CURRENT_WEIGHT,
  CALCULATE_WEIGHT,
} from "src/app/support-functions/calculateWeight";
import { ProfilePicService } from "src/app/admit-form/services/profile-pic.service";
import { numberWithDotValidator } from "src/app/shared/form-validators";
import {
  CalculateLength,
  calculateCurrentHeadCircumference,
  calculateCurrentLength,
} from "src/app/support-functions/calculateLength";
import { TimeZoneDetails } from "src/app/models/hospital";
import { User } from "src/app/models/user";
@Component({
  selector: "app-emr-nav-tabs",
  templateUrl: "./emr-nav-tabs.component.html",
  styleUrls: ["./emr-nav-tabs.component.scss"],
})
export class EmrNavTabsComponent implements OnInit {
  @ViewChild("enterWeigthTemplate") enterWeigthTemplate: ElementRef;

  @Input()
  set pendingCultureCount(count: number) {
    this.cultureCount = count && count > 0 ? count : 0;
  }
  @Input()
  set pendingCovidCount(count: number) {
    this.covidCount = count && count > 0 ? count : 0;
  }
  @Input("currentUser") public currentUser: User | undefined;

  public cultureCount = 0;
  public covidCount = 0;
  public draftNote$ = this.store.select(fromViewNoteStore.getAllDraftNotes);

  private unsubscribe$: Subject<any> = new Subject<any>();

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  public CPMRN;
  public encounters;
  public selected: string;
  public notesCount = 0;
  public patientInfo: Patient;
  public patientWeightHistory: any = [];
  public patientAvatar: AvatarParams[];
  public patientType = "";
  public isDischargeTimeElapsed = false;
  public patCurrentWObj: any;
  public patBirthWStr: string;
  public patAdminWStr: string;
  public patCorrGestAgeStr: string;
  public patCurrLengthObj: any;
  public patCurrHeadCircufObj: any;
  public patAge: any;

  /** nav tabs' icon's url */
  notesImageUrl: string;
  ordersImageUrl: string;
  vitalsImageUrl: string;
  assessmentImageUrl: string;
  marImageUrl: string;
  labsScansImageUrl: string;
  ioImageUrl: string;
  summaryImageUrl: string;
  roundarImageUrl: string;

  tabInFocus: string = "summary";

  /* ICONS */
  faBackward = faBackward;
  faHeartBeat = faHeartbeat;
  faFileMedicalAlt = faFileMedicalAlt;
  faClipboard = faClipboard;
  faCircle = faCircle;
  faExchangeAlt = faExchangeAlt;
  faFileUpload = faFileUpload;
  faListAlt = faListAlt;
  faRedo = faRedo;
  faNotesMedical = faNotesMedical;

  profilePic: string;

  weight = new FormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.min(1),
      Validators.max(999000),
    ])
  );
  growthParamForm = this._fb.group({
    weight: [
      null,

      Validators.compose([
        Validators.min(1),
        Validators.max(999000),
        numberWithDotValidator,
      ]),
    ],
    length: [
      null,
      Validators.compose([
        Validators.min(1),
        Validators.max(300),
        numberWithDotValidator,
      ]),
    ],
    headCircumference: [
      null,
      Validators.compose([
        Validators.min(1),
        Validators.max(100),
        numberWithDotValidator,
      ]),
    ],
  });

  get growthFormIsEmpty() {
    return !Object.values(this.growthParamForm?.value).some((val) => val);
  }

  public get PatientType() {
    return PatientType;
  }
  public timeZoneDetail: TimeZoneDetails;
  constructor(
    private router: Router,
    public store: Store<{}>,
    private _patientService: PatientService,
    private _route: ActivatedRoute,
    private dialog: MatDialog,
    private _alertService: AlertService,
    private _formActions: FormsService,
    private _service: ProfilePicService,
    private _fb: FormBuilder
  ) {}

  backToPatList() {
    this.router.navigate(["patientlist"]);
  }

  goTo(...childRoute: string[]) {
    this.router.navigate(childRoute, { relativeTo: this._route });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.tabInFocus = this.router.url.split("/").reverse()[0];
        const indexOf = this.tabInFocus.indexOf("?");
        if (indexOf !== -1) {
          this.tabInFocus = this.tabInFocus.substring(0, indexOf);
        }
        this.setNavTabsIconUrl();
      });

    /* Get Patient info */
    this.patientHeader$.subscribe((patient) => {
      this.patientInfo = JSON.parse(JSON.stringify(patient));

      if (patient && patient.CPMRN) {
        this.timeZoneDetail =
          this.patientInfo?.timeZoneDetail ||
          this.patientInfo?.hospitalInfo?.timeZoneDetail;
        this.CPMRN = patient.CPMRN;
        this.encounters = patient.encounters;
        this.calculatePatientBioMetrics();
      }

      this.setPatientType(patient);

      this.isDischargeTimeElapsed =
        this._patientService.checkIfDischargeTimeElapsed(patient);
      if (this.patientInfo?.profilePicture && !this.profilePic) {
        this._service
          .getImage(this.patientInfo.CPMRN, this.patientInfo.encounters)
          .subscribe((res) => {
            this.profilePic = res.data.signedUrl;
            this.patientAvatar = [
              {
                name: this.patientInfo?.name,
                size: AVATAR_SIZE.L,
                imageUrl: this.profilePic,
              },
            ];
          });
      } else {
        this.patientAvatar = [
          {
            name: this.patientInfo?.name,
            size: AVATAR_SIZE.L,
          },
        ];
      }
    });

    /* Highlight the drafted notes */

    /** Set patient's avatar */

    /** set tabInFocus and icons of nav tabs */

    let currRoute = this.router.url.split("/");
    this.tabInFocus = currRoute[currRoute.length - 1];

    this.setNavTabsIconUrl();
  }

  // set patient type

  setPatientType(data) {
    if (data.isCurrentlyAdmitted !== null)
      this.patientType = data.isCurrentlyAdmitted
        ? this.encounters > 1
          ? "re-admitted"
          : "admitted"
        : "discharged";
  }

  // returns rounded value upto 2 decimals.
  getRoundedValue(value) {
    return Math.round(value * 100) / 100;
  }

  calculatePatientBioMetrics() {
    this.patAdminWStr = this.calculateAdminW() as string;
    this.patAge = this.getPatientAge();
    if (this.patientInfo?.patientType !== PatientType.Neonatal) return;

    this.patBirthWStr = this.calculateBirthW() as string;
    this.patCurrentWObj = this.calculateCurrW();
    this.patCorrGestAgeStr = this.getCorrectedGestationalAge();
    this.patCurrLengthObj = this.calculateCurrLength();
    this.patCurrHeadCircufObj = this.calculateCurrHeadCircumference();
  }

  calculateAdminW() {
    const payload: CALCULATE_WEIGHT = {
      weightObj: this.patientInfo?.weightObj,
      patientType: this.patientInfo?.patientType,
      weightInGrams: true,
    };
    return calculateWeight(payload) || "-";
  }

  calculateBirthW() {
    const payload: CALCULATE_WEIGHT = {
      weightObj: this.patientInfo?.birthWeightObj,
      patientType: this.patientInfo?.patientType,
      weightInGrams: true,
    };
    return calculateWeight(payload) || "-";
  }

  calculateCurrW() {
    const payload: CALCULATE_CURRENT_WEIGHT = {
      weightHistory: this.patientInfo?.weightHistory,
      weightObj: this.patientInfo?.weightObj,
      patientType: this.patientInfo?.patientType,
      weightInGrams: true,
      returnWeightHistoryObj: true,
    };
    return calculateCurrentWeight(payload);
  }

  calculateCurrLength() {
    const payload: CalculateLength = {
      growthHistory: this.patientInfo?.weightHistory,
      patientType: this.patientInfo?.patientType,
    };
    return calculateCurrentLength(payload);
  }

  calculateCurrHeadCircumference() {
    const payload: CalculateLength = {
      growthHistory: this.patientInfo?.weightHistory,
      patientType: this.patientInfo?.patientType,
    };
    return calculateCurrentHeadCircumference(payload);
  }

  getCorrectedGestationalAge() {
    let { weeks: gestAgeW, days: gestAgeD } = this.patientInfo?.gestationAge;
    let [patientAge, patientAgeUnit] = this.patAge?.split(" ");
    if (patientAgeUnit?.includes("hr"))
      patientAge = Math.floor(patientAge / 24);

    const newDaysInGestationAge = gestAgeD + +patientAge;
    const noOfWeeks = Math.floor(newDaysInGestationAge / 7);
    const remainingDays = Math.floor(newDaysInGestationAge % 7);

    gestAgeW = gestAgeW + noOfWeeks;
    gestAgeD = remainingDays;

    return `${gestAgeW > 0 ? gestAgeW + "w " : ""}${
      gestAgeD > 0 ? gestAgeD + "d" : ""
    }`;
  }

  /** To set the icon-url of the selected and rest of the nav-tab */

  setNavTabsIconUrl() {
    this.summaryImageUrl =
      this.tabInFocus === "summary"
        ? "assets/icons/summary_white.svg"
        : "assets/icons/summary.svg";
    this.notesImageUrl =
      this.tabInFocus === "notes"
        ? "assets/icons/notes_white.svg"
        : "assets/icons/notes.svg";
    this.ordersImageUrl =
      this.tabInFocus === "orders"
        ? "assets/icons/orders_white.svg"
        : "assets/icons/orders.svg";
    this.vitalsImageUrl =
      this.tabInFocus === "vitals"
        ? "assets/icons/vitals_white.svg"
        : "assets/icons/vitals.svg";
    this.assessmentImageUrl =
      this.tabInFocus === "assessment"
        ? "assets/icons/assessment_white.svg"
        : "assets/icons/assessment.svg";
    this.marImageUrl =
      this.tabInFocus === "mar"
        ? "assets/icons/mar_white.svg"
        : "assets/icons/mar.svg";
    this.labsScansImageUrl =
      this.tabInFocus === "labs"
        ? "assets/icons/labs_scans_white.svg"
        : "assets/icons/labs_scans.svg";
    this.ioImageUrl =
      this.tabInFocus === "intake-output"
        ? "assets/icons/input_output_white.svg"
        : "assets/icons/input_output.svg";
    this.roundarImageUrl =
      this.tabInFocus === "notebook"
        ? "assets/icons/roundar_white.svg"
        : "assets/icons/roundar.svg";
  }

  getPatientAge() {
    const ageObj = this.patientInfo?.age;

    return displayAge(
      ageObj,
      true,
      this.patientInfo.patientType,
      this.patientInfo.dob
    );
  }

  openEnterWeigth(content) {
    const dialogRef = this.dialog.open(content, {
      height: "238px",
      width: "640px",
      autoFocus: false,
      disableClose: true,
    });
  }

  updateWeight() {
    const weightObj = {
      ...this.growthParamForm.value,
      weightUnit: "gm",
    };
    const updatedProperties = this._formActions.transformUpdateData(weightObj);
    const payload = {
      CPMRN: this.CPMRN,
      encounters: this.encounters,
      patient: updatedProperties,
    };
    this.store.dispatch(
      fromPatientFormActions.updatePatient({
        payload,
        nextPage: 0,
        isFormComplete: false,
      })
    );
    this.growthParamForm.reset();
    this._alertService.showNotification({
      type: "Success",
      message: "Growth measurements updated successfully",
    });
  }
}
