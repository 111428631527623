<div class="alert-container">
  <div
    class="alert-header"
    [class]="isCodeBlue ? 'codeblue-header' : 'ecall-header'"
  >
    <div class="logo-and-name">
      <img
        [src]="
          isCodeBlue ? 'assets/icons/code_blue.svg' : 'assets/icons/Ecall.svg'
        "
      />

      {{ isCodeBlue ? "CodeBlue" : "eCall" }}
    </div>
    <img
      class="close-icon"
      [src]="
        isCodeBlue
          ? 'assets/icons/close_white.svg'
          : 'assets/icons/close_black.svg'
      "
      (click)="closeDialog()"
      [style.cursor]="'pointer'"
    />
  </div>
  <div class="alert-body">
    <!-- ALERTS DETAILS -->
    <div class="alert-details">
      <!--  Hospital selection  -->
      <div class="hospital-selection" *ngIf="selectedHospital">
        <div class="light-headings">Hospital:</div>
        <div>
          {{ selectedHospital }}
        </div>
      </div>

      <ng-container
        *ngIf="isCommandCenterUser && !selectedHospital; else remoteUserBlock"
      >
        <div class="hospital-selection-box">
          <div class="hospital-search-label">
            {{
              "Select the hospital to raise " +
                (isCodeBlue ? "CodeBlue" : "eCall")
            }}
          </div>
          <div class="search-input-box">
            <mat-form-field [floatLabel]="'always'">
              <mat-label></mat-label>
              <input
                matInput
                autofocus
                [(ngModel)]="searchText"
                type="text"
                placeholder="Search hospital"
                class="hospital-input"
              />
            </mat-form-field>
          </div>
          <!-- No-data (hospital)-found  -->
          <div *ngIf="assignedHospitals?.length === 0; else hospitalNamesBlock">
            No data found
          </div>
          <ng-template #hospitalNamesBlock>
            <div class="hospitals-list-box">
              <div class="hospital-header">Hospital</div>
              <div class="options">
                <div
                  class="hospitalName display-flex align-items-center cp-px-2"
                  *ngFor="let hospital of filteredHospitals"
                  (click)="onHospitalSelect(hospital)"
                >
                  {{ hospital }}
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #remoteUserBlock>
        <div *ngIf="!selectedHospital && assignedHospitals?.length > 1">
          Select Hospital
          <div class="alert-select-button-group">
            <ng-container *ngFor="let hospital of assignedHospitals">
              <button
                class="alert-button"
                [class]="isCodeBlue ? 'codeblue-button' : 'ecall-button'"
                (click)="onHospitalSelect(hospital)"
              >
                {{ hospital }}
              </button>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!selectedHospital && !(assignedHospitals?.length > 0)">
          No Hospital Found!
        </div>
      </ng-template>

      <!--  Unit selection  -->
      <div *ngIf="selectedHospital">
        <div class="hospital-selection" *ngIf="selectedUnit">
          <div class="light-headings">Unit:</div>
          <div>
            {{ selectedUnit }}
          </div>
        </div>
        <div
          class="unit-choice"
          *ngIf="!selectedUnit && assignedUnits?.length > 1"
        >
          Select Unit
          <div class="alert-select-button-group">
            <ng-container *ngFor="let unit of assignedUnits">
              <button
                class="alert-button"
                [class]="isCodeBlue ? 'codeblue-button' : 'ecall-button'"
                (click)="onUnitSelect(unit)"
              >
                {{ unit }}
              </button>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!selectedUnit && !(assignedUnits?.length > 0)">
          No Assigend Unit!
        </div>
      </div>
      <!--  Bed selection  -->
      <div *ngIf="selectedUnit">
        <div class="hospital-selection" *ngIf="selectedBed !== '-'">
          <div class="light-headings">Bed:</div>
          <div>
            {{ selectedBed }}
          </div>
        </div>
        <div class="bed-choice" *ngIf="assignedBeds?.length > 0">
          <div *ngIf="selectedBed === '-'">Select Bed</div>
          <div class="alert-select-button-group">
            <ng-container *ngFor="let bed of assignedBeds">
              <button
                class="alert-button-bed"
                [class]="isCodeBlue ? 'codeblue-button' : 'ecall-button'"
                (click)="onBedSelect(bed.bedNumber)"
                [disabled]="bed.isAlertRunning"
                tabindex="-1"
              >
                {{ bed.bedNumber }}
              </button>
            </ng-container>
          </div>
        </div>
        <div *ngIf="!(assignedBeds?.length > 0)">No Assigend Bed!</div>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="alert-buttons">
      <div class="condition-info">
        {{
          "*You will be redirected to Google Meet once " +
            (isCodeBlue ? "CodeBlue" : "eCall") +
            " is raised."
        }}
      </div>
      <div class="action-button-group">
        <button class="button-tertiary" (click)="onClickBack()">back</button>
        <button
          autofocus
          [disabled]="isRaiseAlertDisabled"
          (click)="raiseAlert()"
          [class]="isCodeBlue ? 'raise-codeblue-button' : 'raise-ecall-button'"
        >
          {{ isCodeBlue ? "Raise CodeBlue" : "Raise eCall" }}
        </button>
      </div>
    </div>

    <!-- ONGOING ALERTS -->
    <div class="ongoing-alert" *ngIf="runningAlerts?.length > 0">
      <div class="ongoing-call-text">
        {{ "Ongoing " + (isCodeBlue ? " CodeBlue" : " eCall") }}
      </div>
      <div class="horizontal-bar"></div>
      <div class="ongoing-alert-group">
        <ng-container *ngFor="let alert of runningAlerts">
          <div class="running-alert-tab">
            <div class="alert-tab-object-hospital">
              <div class="alert-tab-key">Hospital</div>
              <div class="alert-tab-value">{{ alert.hospital }}</div>
            </div>
            <div class="alert-tab-object">
              <div class="alert-tab-key">Unit</div>
              <div class="alert-tab-value">{{ alert.unit }}</div>
            </div>
            <div class="alert-tab-object">
              <div class="alert-tab-key">Bed no</div>
              <div class="alert-tab-value">{{ alert.bed_number }}</div>
            </div>
            <div class="alert-tab-object">
              <div class="alert-tab-key">Raised by</div>
              <div class="alert-tab-value">{{ alert.raised_by_name }}</div>
            </div>
            <div class="alert-tab-object">
              <div class="alert-tab-key">Running for</div>
              <div class="alert-tab-value">
                {{ alert.runningFor }}
              </div>
            </div>
            <!-- <div class="alert-tab-object">
              <div class="alert-tab-key">Attendees</div>
              <div class="alert-tab-value">0</div>
            </div> -->
            <div class="alert-tab-object">
              <div class="alert-tab-key">Team</div>
              <cp-assigned-users
                *ngIf="alert.patient_info?.is_admitted"
                [commandCenterID]="alert.patient_info?.command_center_id"
                [cpmrn]="alert.patient_info?.cpmrn"
                [unitID]="alert.patient_info?.unit_id"
                [encounter]="alert.patient_info?.encounters"
              >
              </cp-assigned-users>
            </div>
            <div class="alert-tab-object-small">
              <cp-meet-link
                [href]="convertToHTTPS(alert.google_meet_link)"
              ></cp-meet-link>
            </div>
            <div class="alert-tab-object-small">
              <img
                src="/assets/icons/notification-icons/close.svg"
                [style.cursor]="'pointer'"
                (click)="onCancelAlert(alert)"
              />
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
