export const environment = {
  production: false,
  apiUrl: "https://qa.cloudphysicianworld.org/api/",
  devResUrl: "https://qa.cloudphysicianworld.org/api/",
  ptzApiUrl: "https://qa.cloudphysicianworld.org/api/",
  socketUrl: "https://qa.cloudphysicianworld.org/",
  billingUrl: "https://prod.cloudphysicianworld.com/api3/",
  aiSocketUrl: "https://qa.cloudphysicianworld.org/api_ai/",
  vitalsUrl: "https://cloudphysicianworld.com/api4/",
  recaptchaKey: "6Le9MMAUAAAAAACoh3Dqm6siCb5ewnZ_svviFTbJ",
  passwordSecretKey: "CloudPhysician",
  wikiDomain: "api.cloudphysicianworld.org",
  s3region: "ap-south-1",
  socketPath: "/api/CP-EMR",
  s3bucket: "emr-dev-public",
};
