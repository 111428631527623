import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import {
  DisplayShortcutApi,
  Orderable,
  SetDefaultApi,
} from "../models/Orderable.model";
import { ApiResponse } from "../models/api-response.model";
import { environment } from "../../environments/environment";
import { HttpErrorHandlerService } from "./http-error-handler.service";
import {
  OrderableRequest,
  OrderableRequestAcceptRequest,
} from "../models/orderable/OrderableRequest.model";
import {
  PresetGetRequest,
  PresetPostRequest,
} from "../models/orderable/PresetRequest.model";

@Injectable({ providedIn: "root" })
export class OrderableService {
  public apiUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandlerService
  ) {}

  /**
   * Issues a request to server to get an orderable.
   *
   * @param {string} id
   * @returns {Observable<any>}
   */
  get(id: string, brand: string = null): Observable<any> {
    return this.http.get<ApiResponse>(`${this.apiUrl}orderable/${id}`, {
      ...this.httpOptions,
      params: new HttpParams().set("brand", brand),
    });
  }

  /**
   * Issues a request to server to create an order.
   *
   * @param {Orderable} orderable
   * @returns {Observable<any>}
   */
  create(orderable: Orderable): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}orderable`,
      orderable,
      this.httpOptions
    );
  }

  /**
   * Issues a request to server to edit an order.
   *
   * @param {string} id - order id
   * @param {Orderable} orderable
   * @returns {Observable<any>}
   */
  edit(id: string, orderable: Orderable): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}orderable/${id}`,
      orderable,
      this.httpOptions
    );
  }

  /**
   * Issues a request to server to delete an order.
   *
   * @param {string} id - order id
   * @returns {Observable<any>}
   */
  delete(id: string): Observable<any> {
    return this.http.delete<ApiResponse>(
      `${this.apiUrl}orderable/${id}`,
      this.httpOptions
    );
  }

  setDefault(data: SetDefaultApi): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}orderable/${data.orderableId}/preset/${data.presetId}/setDefault`,
      { default: data.default },
      this.httpOptions
    );
  }

  /**
   * Issues a request to server to check if orderName is unique.
   * Used with nameExist validator.
   *
   * @param {string} name - orderName
   * @param {string} id - id of the order
   * @returns {Observable<boolean>}
   */
  checkIfNameExist(name: string, id: string): Observable<boolean> {
    return this.http
      .post<ApiResponse>(
        `${this.apiUrl}orderable/check`,
        { name, id },
        this.httpOptions
      )
      .pipe(
        catchError(this.httpErrorHandler.handleError()),
        map((data) => data.data)
      );
  }

  deletePreset(orderableId: string, presetId: string): Observable<any> {
    return this.http.delete<ApiResponse>(
      `${this.apiUrl}orderable/${orderableId}/preset/${presetId}`,
      this.httpOptions
    );
  }

  setDisplayShortcut(data: DisplayShortcutApi): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}orderable/${data.orderableId}/preset/${data.presetId}/setDisplayShortcut`,
      { display: data.display },
      this.httpOptions
    );
  }

  submitOrderableRequest(data: OrderableRequest): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}orderable/request`,
      data,
      this.httpOptions
    );
  }

  getOrderableRequest(): Observable<any> {
    return this.http.get<ApiResponse>(
      `${this.apiUrl}orderable/request`,
      this.httpOptions
    );
  }

  acceptOrderableRequest(
    id: string,
    data: OrderableRequestAcceptRequest
  ): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}orderable/request/${id}/accept`,
      data,
      this.httpOptions
    );
  }

  rejectOrderableRequest(id: string): Observable<any> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}orderable/request/${id}/reject`,
      {},
      this.httpOptions
    );
  }

  getPresetRequest({
    status,
    page,
    size,
    searchStr = "",
  }: PresetGetRequest): Observable<any> {
    let queryParam = {};
    if (searchStr) {
      queryParam = {
        params: {
          searchStr,
        },
      };
    }
    return this.http.get<ApiResponse>(
      `${this.apiUrl}v1/orderable-preset-request/${page}/${size}/${status}`,
      queryParam
    );
  }

  submitPresetRequest(payload: PresetPostRequest): Observable<any> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}v1/orderable-preset-request/status`,
      payload,
      this.httpOptions
    );
  }
}
