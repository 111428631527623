import { Attributes } from "../labs-scans/models/labs-scans";

export function getPatTypes(fields: Attributes[]) {
  // Define validation ranges for different patient types
  const validationRanges: Record<string, string[]> = {
    adult: ["errorRange", "validationRange"],
    pediatric: ["pediatricErrorRange", "pediatricValidationRange"],
    neonatal: ["neonatalErrorRange", "neonatalValidationRange"],
  };

  // Determine which patient types have valid ranges set
  return Object.entries(validationRanges).reduce(
    (types, [patientCategory, rangeFields]) => {
      const hasValidRanges = rangeFields.some((range) =>
        fields.some((field) => !!(field[range]?.min || field[range]?.max))
      );
      if (hasValidRanges) {
        types.push(patientCategory);
      }
      return types;
    },
    [] as string[]
  );
}
