<a target="_blank" class="cp-text-decoration-none" [href]="href">
  <div class="display-flex align-items-center gap-1">
    <mat-icon
      svgIcon="cp-meet-link"
      [class.cp-icon]="defaultIconColor"
      aria-hidden="false"
      aria-label="meet icon"
      [matTooltip]="tooltipText"
    >
    </mat-icon>
    <ng-content></ng-content>
  </div>
</a>
