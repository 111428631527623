import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvatarComponent } from "./avatar.component";
import { ShortNameModule } from "@shared-modules/pipes/short-name/short-name.module";

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, ShortNameModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
