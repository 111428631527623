import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { HandoverNotesService } from "src/app/handover/shared/services/handover-notes.service";
import { maxCharectors } from "src/app/handover/shared/utils/validation";

@Component({
  selector: "cp-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrls: ["./text-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextEditorComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TextEditorComponent,
      multi: true,
    },
  ],
})
export class TextEditorComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  public contentControl = new FormControl("", [
    Validators.maxLength(maxCharectors),
  ]);

  public unsubscribe$ = new Subject();

  @Input("height") height: number = null;
  @Input("toolBarFixed") toolBarFixed: boolean = false;
  @Input("cpmrn") cpmrn: string | undefined = null;
  @Input("encounters") encounters: number | undefined;
  @Input("handOverId") handOverId: string | undefined;
  @Input("autosave") autosave: boolean = true;

  @Input("hasBorder") hasBorder: boolean = true;

  @Output() createdId: EventEmitter<string> = new EventEmitter<string>();

  onChange: (value: string) => void;
  onTouched: () => void;
  serverError: boolean = false;
  isNoteSaving: boolean;
  quillElement: any;
  quill: any;

  constructor(
    private _handoverNotes: HandoverNotesService,
    private _cdr: ChangeDetectorRef
  ) {}
  writeValue(obj: string): void {
    this.contentControl.setValue(obj);
    this.setSelection();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) this.contentControl.disable();
    else this.contentControl.enable();
  }

  @ViewChild("quillEditor") quillEditor: ElementRef;

  public isSelected: boolean = false;

  ngOnInit(): void {
    this.initializeValues();
    this.listenToEvents();
  }

  initializeValues() {
    if (this.encounters) this.isSelected = true;
  }

  listenToEvents() {
    const valueChanges = this.contentControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    );

    valueChanges.subscribe((val) => {
      if (!this.onChange || !this.onTouched || this.contentControl.pristine)
        return;
      const inputValue = this.transformInput(val);
      this.onChange(inputValue);
      this.onTouched();
    });

    valueChanges.pipe(debounceTime(500)).subscribe((note: string) => {
      if (!this.autosave) return;
      this.saveNotes(note);
    });
  }

  validate(): ValidationErrors | null {
    return this.contentControl.errors;
  }

  saveNotes(note: string | null) {
    if (this.contentControl.invalid || this.contentControl.pristine) return;
    this.serverError = false;
    if (this.handOverId) {
      this.updateNote(note);
    } else {
      if (!note) return;
      if (!this.cpmrn || !this.encounters) {
        console.error("cpmrn and encounters are required for creation");
        return;
      }
      this.isNoteSaving = true;
      this.createNote(note);
    }
  }

  createNote(note: string) {
    this._handoverNotes
      .postHandoverNotes({
        cpmrn: this.cpmrn,
        encounter: this.encounters,
        note,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          this.isNoteSaving = false;
          this.createdId.emit(data._id);
          this.handOverId = data._id;
        },
        (_) => {
          this.serverError = true;
          this._cdr.detectChanges();
        }
      );
  }

  updateNote(note: string): void {
    this._handoverNotes
      .updateHandoverNotes([
        {
          noteId: this.handOverId,
          handoverStatus: false,
          note: note || "",
        },
      ])
      .subscribe(
        () => {},
        (_) => {
          this.serverError = true;
          this._cdr.detectChanges();
        }
      );
  }

  public transformInput(val: string): string {
    const isEmpty =
      this.contentControl?.value
        ?.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
        .trim() == "";
    if (isEmpty) return "";
    else return val;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setEditor(quill) {
    this.quill = quill;
    this.setSelection();
  }

  setSelection() {
    if (this.quill) {
      const length = this.quill.getLength();
      this.quill.setSelection(length, length);
      this.quill.focus();
    }
  }

  @HostListener("document:click", ["$event"])
  onClick(event) {
    if (this.toolBarFixed) return;
    const targetElement = this.quillEditor.nativeElement;
    if (!targetElement.contains(event.target)) {
      this.isSelected = false;
      return;
    }
    this.isSelected = true;
  }
}
