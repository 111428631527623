import { Component, OnInit } from "@angular/core";
import { version } from "src/version/version";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-cp-footer",
  templateUrl: "./cp-footer.component.html",
  styleUrls: ["./cp-footer.component.scss"],
})
export class CpFooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  environment = environment;

  /**
   * @description This is to display the version no of the software
   * @author Suraj Shenoy
   */
  versionNumber: string = version.no;

  constructor() {}

  ngOnInit(): void {}
}
