import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DateTime } from "luxon";
import { AISocketService } from "../services/socket.service";
import { NotificationType } from "../enums/notificationtype.enum";
import { Topic } from "../enums/topic.enum";
import { NetraMissedNotificationComponent } from "../netra-missed-notification/netra-missed-notification.component";
import { NetraNotVerifiedNotificationComponent } from "../netra-not-verified-notification/netra-not-verified-notification.component";

@Component({
  selector: "app-notification-element",
  templateUrl: "./notification-element.component.html",
  styleUrls: ["./notification-element.component.scss"],
})
export class NotificationElementComponent implements OnInit, OnDestroy {
  @Input() notificationContent: any;
  @Output() notificationClicked = new EventEmitter<void>();

  public notifications = NotificationType; // expose the enum to the template
  public notificationType: string;
  public notificationTimestamp: string;
  public timePassed: string;
  public isUnread: boolean;
  public abnormalVitals: string;
  private updateNotificationTimer: any;
  private currentUser: any;

  constructor(
    public dialog: MatDialog,
    private socketService: AISocketService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.isUnread = !this.notificationContent["is_read"];
    this.notificationType = this.notificationContent["type"];
    this.updateNotificationTimestamp();
    if (this.notificationContent["abnormal_vitals"]) {
      this.abnormalVitals = "";
      const vitalsLength = Object.keys(
        this.notificationContent["abnormal_vitals"]
      ).length;
      let index = 0;
      Object.keys(this.notificationContent["abnormal_vitals"]).forEach(
        (key) => {
          index = index + 1;
          const separator =
            index > 1 ? (index < vitalsLength ? ", " : " and ") : "";
          this.abnormalVitals = this.abnormalVitals + separator;
          this.abnormalVitals =
            this.abnormalVitals +
            key +
            ":" +
            this.notificationContent["abnormal_vitals"][key];
        }
      );
    }
    // update each notification time every minute
    this.updateNotificationTimer = setTimeout(() => {
      this.updateNotificationTimestamp();
    }, 60000); // 60000 milliseconds = 1 minute
  }

  ngOnDestroy() {
    clearTimeout(this.updateNotificationTimer);
  }

  updateNotificationTimestamp() {
    this.notificationTimestamp = this.formatDateTime(
      this.notificationContent["timestamp"]
    );
    if (this.notificationContent["raised_at"]) {
      const now = DateTime.local();
      const targetTime = DateTime.fromISO(
        this.notificationContent["raised_at"],
        { zone: "utc" }
      );
      const minutesAgo = Math.floor(now.diff(targetTime).as("minutes"));
      this.timePassed = minutesAgo + " min" + (minutesAgo > 1 ? "s" : "");
    }
  }

  calculateTimeDuration(): string {
    let endTimestamp = this.notificationContent.completed_at;
    if (!endTimestamp || endTimestamp === "") {
      // if alert is not completed then it's canceled
      endTimestamp = this.notificationContent.cancelled_at;
    }
    const startTime = DateTime.fromISO(this.notificationContent["raised_at"], {
      zone: "utc",
    });
    const endTime = DateTime.fromISO(endTimestamp, { zone: "utc" });
    const minutesAgo = Math.floor(endTime.diff(startTime).as("minutes"));
    const duration = minutesAgo + " min" + (minutesAgo > 1 ? "s" : "");
    return duration;
  }

  formatDateTime(timestamp: string): string {
    const now = DateTime.local();
    const targetTime = DateTime.fromISO(timestamp, { zone: "utc" });
    const minutesAgo = Math.floor(now.diff(targetTime).as("minutes"));
    const hoursAgo = Math.floor(now.diff(targetTime).as("hours"));
    const daysAgo = Math.floor(now.diff(targetTime).as("days"));
    if (minutesAgo < 1) {
      return "Just now";
    } else if (minutesAgo < 60) {
      return `${minutesAgo}m ago`;
    } else if (hoursAgo < 24) {
      return `${hoursAgo}h ago`;
    } else {
      return `${daysAgo}day${daysAgo > 1 ? "s" : ""} ago`;
    }
  }

  getFormatedTime(timestamp: string): string {
    const dateTime = DateTime.fromISO(timestamp, { zone: "utc" });
    // convert to local time and format as hh:mm
    const localTime = dateTime.toLocal().toFormat("hh:mm");
    return localTime;
  }

  openNotification(): void {
    this.notificationClicked.emit();
    if (this.notificationType === this.notifications.netraMissedVital) {
      this.dialog.open(NetraMissedNotificationComponent, {
        width: "720px",
        height: "480px",
        data: this.notificationContent["notification_data"],
        panelClass: "notification-dialog-container",
      });
    } else if (this.notificationType === this.notifications.netraNotVerified) {
      this.dialog.open(NetraNotVerifiedNotificationComponent, {
        width: "720px",
        height: "480px",
        data: this.notificationContent["notification_data"],
        panelClass: "notification-dialog-container",
      });
    }
  }

  toggleResolve(): void {
    let is_resolved = true;
    if (this.notificationContent["is_resolved"]) {
      is_resolved = false;
    }
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const notificationData = {
      role: currentUser["role"],
      email: currentUser["email"],
      notification_ids: this.notificationContent["_id"],
      is_resolved: is_resolved,
    };
    this.socketService.send(Topic.markNotificationResolved, notificationData);
  }

  endAlertEvent(): void {
    this.notificationContent["completed_by_role"] = this.currentUser["role"];
    this.notificationContent["completed_by_email"] = this.currentUser["email"];
    this.notificationContent["completed_by_name"] = this.currentUser["name"];
    this.socketService.send(Topic.completeRalert, this.notificationContent);
  }

  convertToHTTPS(link: string): string {
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return link;
    }
    return `https://${link}`;
  }

  openVitalPage(): void {
    const url =
      "/patient/" +
      this.notificationContent.cpmrn +
      "/" +
      this.notificationContent.encounters +
      "/entry/vitals";
    window.open(url, "_blank");
  }

  openNotesPage(): void {
    const url =
      "/patient/" +
      this.notificationContent.cpmrn +
      "/" +
      this.notificationContent.encounters +
      "/notes";
    window.open(url, "_blank");
  }
}
