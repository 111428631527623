import { Component, Input, OnInit, inject } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "cp-meet-link",
  templateUrl: "./meet-link.component.html",
  styleUrls: ["./meet-link.component.scss"],
})
export class MeetLinkComponent implements OnInit {
  @Input("href") href: string = "";
  @Input("defaultIconColor") defaultIconColor: boolean = true;
  @Input("tooltip") tooltip: boolean = true;
  private readonly _matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private readonly _domSanitizer: DomSanitizer = inject(DomSanitizer);
  tooltipText: string = "";
  constructor() {
    this._matIconRegistry.addSvgIcon(
      "cp-meet-link",
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/meet-link.svg"
      )
    );
  }
  ngOnInit(): void {
    if (this.tooltip) {
      this.tooltipText = "Open meet link";
    }
  }
}
