import { MeetLinkComponent } from "./meet-link.component";

import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [MeetLinkComponent],
  imports: [MatTooltipModule, MatIconModule],
  exports: [MeetLinkComponent],
})
export class MeetLinkModule {}
