import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  HostListener,
} from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { DateTime } from "luxon";
import { Subscription, interval } from "rxjs";
import { AISocketService } from "src/app/notification-module/services/socket.service";
import { Topic } from "src/app/notification-module/enums/topic.enum";
import { CodeblueCancelComponent } from "../codeblue-cancel/codeblue-cancel.component";
import { DataService } from "../service/data.service";

@Component({
  selector: "app-codeblue-sender",
  templateUrl: "./codeblue-sender.component.html",
  styleUrls: ["./codeblue-sender.component.scss"],
})
export class CodeblueSenderComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private timerSubscription: Subscription;
  private currentUser: any;
  private googleMeetLink: string;
  private cancelAlert: any;
  public assignedHospitals: any;
  public assignedUnits: any;
  public availableUnits: any;
  public searchText: string = "";
  public availableHospitals: any;
  public assignedBeds: any = [];
  public selectedHospital: string;
  public selectedUnit: string;
  public selectedBed: string = "-";
  public isCodeBlue: boolean;
  public isRaiseAlertDisabled: boolean;
  public runningAlerts: any = [];
  public isCommandCenterUser: boolean = false;

  constructor(
    private socketService: AISocketService,
    private dataService: DataService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public alertData: any,
    private dialogRef: MatDialogRef<CodeblueSenderComponent>
  ) {}

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent): void {
    const buttonListElements = document.querySelectorAll(".alert-button-bed");
    let clickedInside = false;
    buttonListElements.forEach((element) => {
      if (element.contains(event.target as Node)) {
        clickedInside = true;
      }
    });
    if (!clickedInside) {
      this.selectedBed = "-";
    }
  }

  ngOnInit(): void {
    // first get user details
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.isCommandCenterUser = this.currentUser["isCommandCenterUser"];

    this.subscription = this.socketService
      .subscribeToTopic(Topic.setAssignedUnits)
      .subscribe((message) => {
        if (message) {
          this.assignedUnits = message["assigned_units"];
          this.availableUnits = message["available_units"];
          this.availableHospitals = message["available_hospitals"];
        }
      });

    this.subscription.add(
      this.socketService
        .subscribeToTopic(Topic.setUnitDetails)
        .subscribe((message) => {
          if (message) {
            // set gmeet link for r-alert
            const unitDetails = message["unit_details"];
            this.googleMeetLink = unitDetails["google_meet_link"];
            const bedDetails = unitDetails["bed_details"];
            this.assignedBeds = []; // clean up before new assignment
            Object.keys(bedDetails).forEach((bedNumber) => {
              if (bedDetails[bedNumber]) {
                const bed = {
                  bedNumber: bedNumber,
                  isAlertRunning: false,
                };
                this.assignedBeds.push(bed);
              }
            });
            // bed number should be disabled for running events
            this.runningAlerts.forEach(
              (alert: { unit: string; bed_number: any }) => {
                if (alert.unit === this.selectedUnit) {
                  this.assignedBeds.forEach(
                    (bed: { [x: string]: boolean; bedNumber: any }) => {
                      if (bed.bedNumber === alert.bed_number) {
                        bed["isAlertRunning"] = true;
                      }
                    }
                  );
                }
              }
            );
          }
        })
    );

    if (this.alertData.contentType === "CodeBlue") {
      this.isCodeBlue = true;
    } else {
      this.isCodeBlue = false;
    }
    // initial data set
    this.setAlertData(this.alertData);

    // subscribe to data change
    if (this.isCodeBlue) {
      this.subscription.add(
        this.dataService.getCodeBlueData().subscribe((data) => {
          this.setAlertData(data);
        })
      );
    } else {
      this.subscription.add(
        this.dataService.getEcallData().subscribe((data) => {
          this.setAlertData(data);
        })
      );
    }
  }

  get filteredHospitals() {
    return this.assignedHospitals.filter((hospital) =>
      hospital.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.timerSubscription.unsubscribe();
  }

  setAlertData(alertData: any): void {
    // reset older data
    this.selectedHospital = undefined;
    this.selectedUnit = undefined;
    this.selectedBed = "-";
    // set running alerts
    this.runningAlerts = alertData["runningAlerts"];
    // set hospital and units
    if (this.isCommandCenterUser) {
      // command center user will have all available hospitals for the command center
      this.assignedHospitals = Object.keys(alertData["availableHospitals"]);
      if (this.assignedHospitals.length === 1) {
        this.onHospitalSelect(this.assignedHospitals[0]);
      }
    } else {
      this.assignedHospitals = Object.keys(alertData["availableUnits"]);
      if (this.assignedHospitals.length === 1) {
        this.onHospitalSelect(this.assignedHospitals[0]);
      }
    }
    // sort array of hospital
    this.assignedHospitals.sort((a, b) => a.localeCompare(b));
    // check for unit level running alert
    this.checkRaiseAlertButton();
    // release resources from previous alerts
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    // update all alert's running for time every second
    this.timerSubscription = interval(1000).subscribe(() => {
      // Update the displayed time every second
      this.runningAlerts.forEach(
        (alert: { runningFor: string; raised_at: string }) => {
          alert.runningFor = this.calculateTime(alert.raised_at);
        }
      );
    });
  }

  getAssignedUnits(): void {
    if (this.isCommandCenterUser) {
      this.assignedUnits =
        this.alertData["availableHospitals"][this.selectedHospital];
      if (this.assignedUnits.length === 1) {
        this.onUnitSelect(this.assignedUnits[0]);
      }
    } else {
      this.assignedUnits =
        this.alertData["availableUnits"][this.selectedHospital];
      if (this.assignedUnits.length === 1) {
        this.onUnitSelect(this.assignedUnits[0]);
      }
    }
  }

  onHospitalSelect(hospital: string): void {
    this.selectedHospital = hospital;
    this.getAssignedUnits();
  }

  onUnitSelect(unit: string): void {
    this.selectedUnit = unit;
    const unitData = {
      hospital: this.selectedHospital,
      unit: this.selectedUnit,
    };
    this.socketService.send(Topic.getUnitDetails, unitData);
    // once unit is selected check for already running alerts at unit level
    this.checkRaiseAlertButton();
  }

  checkRaiseAlertButton(): void {
    // if call is not running at unit level enable the raise alert button
    if (this.selectedUnit !== undefined) {
      this.isRaiseAlertDisabled = false;
      this.runningAlerts.forEach((alert) => {
        if (
          alert["bed_number"] === this.selectedBed &&
          alert["unit"] === this.selectedUnit &&
          alert["hospital"] === this.selectedHospital
        ) {
          this.isRaiseAlertDisabled = true;
        }
      });
    } else {
      this.isRaiseAlertDisabled = true;
    }
  }

  onBedSelect(bed: string): void {
    this.selectedBed = bed;
    this.checkRaiseAlertButton();
  }

  onClickBack() {
    if (this.selectedUnit !== undefined && this.assignedUnits.length > 1) {
      this.selectedUnit = undefined;
    } else if (
      this.selectedHospital !== undefined &&
      this.assignedHospitals.length > 1
    ) {
      this.selectedHospital = undefined;
    } else {
      this.closeDialog();
    }
    this.checkRaiseAlertButton();
  }

  raiseAlert() {
    const alertDetails = {
      hospital: this.selectedHospital,
      unit: this.selectedUnit,
      bed_number: this.selectedBed,
      is_codeblue: this.isCodeBlue,
      google_meet_link: this.googleMeetLink,
      raised_by_role: this.currentUser["role"],
      raised_by_email: this.currentUser["email"],
      raised_by_name: this.currentUser["name"],
    };
    this.socketService.send(Topic.setRalert, alertDetails);
    // redirect user to gmeet link
    window.open(this.googleMeetLink, "_blank");
  }

  calculateTime(timestamp: string): string {
    const now = DateTime.local();
    const targetTime = DateTime.fromISO(timestamp, { zone: "utc" });
    const duration = now.diff(targetTime);
    const minutes = Math.floor(duration.as("minutes")); // whole number of minutes
    const seconds = Math.round(duration.as("seconds") % 60); // remaining seconds
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} mins`;
  }

  onCancelAlert(alert: any): void {
    this.cancelAlert = alert;
    this.dialog.open(CodeblueCancelComponent, {
      width: "400px",
      height: "248px",
      data: {
        callback: this.cancelConfirm.bind(this), // bind the context of this
        isCodeBlue: this.isCodeBlue,
      },
    });
  }

  cancelConfirm(reason: string): void {
    this.cancelAlert["cancelled_by_role"] = this.currentUser["role"];
    this.cancelAlert["cancelled_by_email"] = this.currentUser["email"];
    this.cancelAlert["cancelled_by_name"] = this.currentUser["name"];
    this.cancelAlert["cancelled_reason"] = reason;
    this.socketService.send(Topic.cancelRalert, this.cancelAlert);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  convertToHTTPS(link: string): string {
    if (link.startsWith("https://") || link.startsWith("http://")) {
      return link;
    }
    return `https://${link}`;
  }
}
