import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import * as FromDraftActions from "../actions/draft-notes.actions";
import { DraftNotes } from "@notes-view/model/notes-model";

export interface DraftNoteState extends EntityState<DraftNotes> {}

export const noteTypeAdaptor = createEntityAdapter<DraftNotes>({
  selectId: selectNoteId,
});

export const initialState: DraftNoteState = noteTypeAdaptor.getInitialState({});

export function selectNoteId(a: DraftNotes): string {
  return a.refId;
}

const _noteTypeReducer = createReducer(
  initialState,
  on(FromDraftActions.loadDraftNotes, (state, { notes }) => {
    notes = notes.filter((note) => note.noteType.toLowerCase() != "consent");
    return noteTypeAdaptor.setAll(notes, state);
  }),
  on(FromDraftActions.updateDraftNote, (state, { note }) =>
    noteTypeAdaptor.upsertOne(note, state)
  ),
  on(FromDraftActions.deleteDraftNote, (state, { refId }) => {
    if (state.entities[refId]) {
      return noteTypeAdaptor.removeOne(refId, state);
    }
    return state;
  }),
  on(FromDraftActions.resetDraftNoteContent, () => initialState)
);

export function reducer(state: DraftNoteState, action: Action) {
  return _noteTypeReducer(state, action);
}
