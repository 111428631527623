import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SecurityContext,
  ViewChild,
  forwardRef,
  inject,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

import Quill from "quill";

import { OnSelectionChangeInterface, QuillType } from "../../models";
@Component({
  selector: "cp-quill-editor",
  templateUrl: "./quill-editor.component.html",
  styleUrls: ["./quill-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorComponent),
      multi: true,
    },
  ],
})
export class QuillEditorComponent implements OnDestroy, ControlValueAccessor {
  @ViewChild("quillEditor") quillEditor: ElementRef | undefined;
  @Input("hideToolBar") hideToolBar = false;
  @Output() onSelectionChanged = new EventEmitter<OnSelectionChangeInterface>();
  @Output() onEditorCreated = new EventEmitter<Quill>();
  // when user types then it should emit the input
  @Output("input") input = new EventEmitter<string>();
  quill: QuillType = undefined;
  focussed: boolean = false;
  onChange: any;
  onTouched: any;
  disabled: boolean = false;
  public quillContent: string = "";

  $unsubscribe = new Subject();
  disableAutocomplete: boolean = false;

  private _domSanitizer: DomSanitizer = inject(DomSanitizer);

  constructor() {}

  writeValue(obj: any): void {
    this.setQuill(obj);
    this.quillContent = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.setDisabled();
  }

  public setQuill(html: string): void {
    if (!this.quill || html === null) return;

    // Sanitize the HTML before setting it
    const sanitizedHtml = this._domSanitizer.sanitize(
      SecurityContext.HTML,
      html
    );

    this.quill?.setContents(this.quill.clipboard.convert(sanitizedHtml));

    this.setDisabled();
  }

  public setDisabled(): void {
    if (!this.quill) return;
    this.disabled ? this.quill.disable() : this.quill.enable();
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

  onContentChanged = (source: string) => {
    if (source !== "user") return;
    this.emitContent();
  };

  public emitContent() {
    const content = this.quill?.root?.innerHTML;
    if (this.onChange) this.onChange(content);
    this.input.emit(content);
  }

  initializeQuill(quill: Quill) {
    this.quill = quill;
    this.setQuill(this.quillContent);
    this.initializeQuillListeners();
    this.onEditorCreated.emit(this.quill);
  }

  public setText(txt) {
    const selection = this.quill.getSelection(true);
    this.quill.insertText(selection.index, txt);
    this.emitContent();
  }

  initializeQuillListeners() {
    this.quill.on("selection-change", (range, oldRange, source) => {
      if (range) {
        this.onSelectionChanged.emit({
          editor: this.quill,
          oldRange,
          range,
          source,
        });
      }
    });
  }
}
