import { createReducer, on, Action } from "@ngrx/store";

import * as notesAction from "src/app/store/actions/patient-chart/notes/notes.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  indicator: string;
  noteType: string;
  selectedNotesTab: {
    tabType: string;
    isInitialState: boolean;
  };
}

export const initialState: State = {
  indicator: null,
  noteType: null,
  selectedNotesTab: {
    tabType: "signed",
    isInitialState: true,
  },
};

const noteViewReducer = createReducer(
  initialState,
  on(notesAction.setHighlight, (state, { indicator, noteType }) => ({
    ...state,
    indicator: indicator,
    noteType: noteType,
  })),
  on(notesAction.resetHighlight, (_) => initialState),
  on(rootActions.logout, (_) => initialState),
  on(rootActions.patientChartReset, (_) => initialState),
  on(
    notesAction.highlightIncompleteConsent,
    (state, { tabType, isInitialState }) => {
      return {
        ...state,
        selectedNotesTab: { tabType, isInitialState },
      };
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return noteViewReducer(state, action);
}
