import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TextEditorComponent } from "./components/text-editor/text-editor.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SanitizeHtmlModule } from "src/app/shared-modules/pipes/sanitize-html/sanitize-html.module";
import { QuillEditorModule } from "@shared-modules/components/quill-editor";

@NgModule({
  declarations: [TextEditorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SanitizeHtmlModule,
    QuillEditorModule,
  ],
  exports: [TextEditorComponent],
})
export class TextEditorModule {}
