import { Injectable } from "@angular/core";
import { QuillEditorComponent } from "@shared-modules/components/quill-editor";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AugnitoService {
  public cursorIndex: any;
  public contentChangeNotes: string;
  public augnitoText$ = new Subject<string>();
  public augnitoBtnActive = false;
  public hyperText = {
    show: false,
    val: "",
  };

  editorFocusInstance!: QuillEditorComponent;

  constructor() {}

  contentChanged(event: any) {
    this.contentChangeNotes = event.html;
  }

  augnitoText(event: string) {
    if (event) this.augnitoText$.next(event);
  }

  micStatus(event: boolean) {
    this.augnitoBtnActive = event;
  }

  partialTextAugnitochk(event: any) {
    this.hyperText = event;
  }
}
