// note-input.component.ts
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import {
  takeUntil,
  distinctUntilChanged,
  first,
  map,
  take,
} from "rxjs/operators";
import * as fromNoteStore from "@notes/store";
import { Store, select } from "@ngrx/store";
import { PatternValidator } from "src/app/services/pattern-validation/pattern-validators";
import { User } from "src/app/models/user";
import { NoteService } from "@notes/services/note.service";
import {
  PatientTypes,
  UserRolesMap,
} from "src/app/shared/accessControl/roleInterface";
import {
  CTAType,
  NoteFormsDataModel,
  SubTypeForm,
} from "@notes/models/notes.model";
import { NoteFormsService } from "@notes/services/note-forms.service";
import { FinalNotes } from "@notes-view/model/notes-model";
import * as fromloaderStateReducer from "@shared-modules/store";
import { NoteServices } from "src/app/models/patient";
import store from "src/app/react/reactStore/reduxStore";
import { addSelectedTerms } from "src/app/react/reactStore/reactSlice/icdNotesSlice";
import { UtilService } from "src/app/services/util.service";
@Component({
  selector: "cp-note-input",
  templateUrl: "./note-input.component.html",
  styleUrls: ["./note-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: "cp-flex-container cp-h-100",
  },
})
export class NoteInputComponents
  extends UserRolesMap
  implements OnInit, OnDestroy
{
  patientNotesForm: FormGroup;

  private unsubscribe$: Subject<void> = new Subject<void>();

  readonly defaultNoteType = "Select Note Type";

  public currentUser: User;
  public patientType: PatientTypes;
  CTAType = CTAType;
  isHospitalNoteChargeable: boolean;

  public unitType: string;
  @Input() set currPatient(currPatient: any) {
    if (currPatient && currPatient["CPMRN"]) {
      this.initializePatientData(currPatient);
    }
  }

  get getLoaderState$(): Observable<boolean> {
    return this._loaderStore.pipe(
      select(fromloaderStateReducer.getDefaultLoading)
    );
  }

  public noteType$ = this._noteStore.pipe(
    select(fromNoteStore.getAllNoteTypes)
  );

  public noteCTA$ = this._noteStore.pipe(
    select(fromNoteStore.getCTA),
    takeUntil(this.unsubscribe$),
    map((data) => data.cta)
  );

  public isInitialState$ = this._noteStore.pipe(
    select(fromNoteStore.isInitialState)
  );

  constructor(
    public utils: UtilService,
    private patternValidator: PatternValidator,
    public dialog: MatDialog,
    private _noteService: NoteService,
    private _noteFormService: NoteFormsService,
    private _loaderStore: Store<fromloaderStateReducer.ShareState>,
    private _noteStore: Store<fromNoteStore.NoteFormState>
  ) {
    super();
    this.currentUser = this._noteService.user;
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializePatientData(currPatient: any): void {
    this.patientType = currPatient.patientType;
    this.isHospitalNoteChargeable =
      currPatient.hospitalInfo?.noteFeatures?.includes(NoteServices.CHARGEABLE); // this will be removed
    this.dispatchLoadNoteTypesAction();
    this.unitType = currPatient.unitType;
  }

  private initForm(): void {
    this.setupPatientNotesForm();
    this.setupNoteTypeSubscription();
  }

  private setupNoteTypeSubscription(): void {
    this.noteType$.pipe(takeUntil(this.unsubscribe$)).subscribe((noteType) => {
      this.patternValidator.setPatterns("noteType", noteType);
    });
  }

  private loadNoteContents(noteFormContents: {
    noteType: string;
    noteSubType: string;
  }): void {
    this._loaderStore.dispatch(
      fromloaderStateReducer.setLoadingSpinner({
        status: true,
      })
    );
    this._noteStore.dispatch(
      fromNoteStore.loadNoteContents({ noteFormContents })
    );
    store.dispatch(addSelectedTerms([]));
  }

  private setupPatientNotesForm(): void {
    this._noteStore
      .select(fromNoteStore.selectAllNoteContentData)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((data) => this.createPatientNotesForm(data));
  }

  private createPatientNotesForm(data: FinalNotes): void {
    if (data) {
      this.patientNotesForm =
        this._noteFormService.createPatientNotesForm(data);
    }
  }

  private dispatchLoadNoteTypesAction(): void {
    const notetypeCriteria = {
      userRole: this.currentUser.role.toUpperCase(),
      patientType: this.patientType,
    };
    this._noteStore.dispatch(fromNoteStore.loadNoteTypes({ notetypeCriteria }));
  }

  onSelectNoteType(noteType: string): void {
    this._noteStore
      .pipe(
        select(fromNoteStore.selectNoteSubTypesDataByType(noteType)),
        take(1)
      )
      .subscribe(({ subTypes }) =>
        this.handleNoteTypeSelection(subTypes, noteType)
      );
  }

  private handleNoteTypeSelection(
    subTypes: SubTypeForm[],
    noteType: string
  ): void {
    const noteSubtypesArray = subTypes.map(
      (subtype: SubTypeForm) => subtype.noteSubType
    );
    this.updatePatternValidator("noteSubType", noteSubtypesArray);
    const noteSubType = subTypes[0].noteSubType;
    this.loadNoteContents({ noteType, noteSubType });
  }

  get noteSubtypeLists$(): Observable<NoteFormsDataModel> {
    const noteType = this.patientNotesForm.controls.noteType.value;
    return this._noteStore.pipe(
      select(fromNoteStore.selectNoteSubTypesDataByType(noteType)),
      first()
    );
  }

  onSelectNoteSubType(noteSubType: string): void {
    const noteType = this.patientNotesForm.controls.noteType.value;
    this.loadNoteContents({ noteType, noteSubType });
  }

  private updatePatternValidator(
    controlName: string,
    patterns: string[]
  ): void {
    this.patternValidator.setPatterns(controlName, patterns);
  }
}
