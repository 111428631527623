import { Component, inject } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ShiftsEnum } from "src/app/models/patient";
import { PatientListService } from "src/app/services/patient-list.service";

@Component({
  selector: "cp-shift-toggle-button",
  templateUrl: "./shift-toggle-button.component.html",
  styleUrls: ["./shift-toggle-button.component.scss"],
})
export class ShiftToggleButtonComponent {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer
  ) {
    this._matIconRegistry.addSvgIcon(
      `cp-day-shift`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/day_shift.svg"
      )
    );

    this._matIconRegistry.addSvgIcon(
      `cp-day-shift-active`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/day_shift_active.svg"
      )
    );

    this._matIconRegistry.addSvgIcon(
      `cp-night-shift`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "./assets/icons/night_shift.svg"
      )
    );

    this._matIconRegistry.addSvgIcon(
      `cp-night-shift-active`,
      this._sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/night_shift_active.svg"
      )
    );
  }

  public shifts = [ShiftsEnum.DAY, ShiftsEnum.NIGHT];
  public shiftsEnum = ShiftsEnum;
  private _patientListService = inject(PatientListService);

  public shift$ = this._patientListService.shift$;

  public shiftObj = {
    [this.shiftsEnum.DAY]: "cp-day-shift",
    [this.shiftsEnum.NIGHT]: "cp-night-shift",
  };

  public setToggleButton(shift: ShiftsEnum): void {
    this._patientListService.setShift(shift);
  }
}
