import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { QuillEditorComponent } from "./components/text-editor/quill-editor.component";
import { QuillInitDirective } from "./directives/quill-init.directive";

@NgModule({
  declarations: [QuillEditorComponent, QuillInitDirective],
  exports: [QuillEditorComponent],
  imports: [CommonModule, FormsModule],
})
export class QuillEditorModule {}
