<div class="note-main__right-sec">
  <!-- <app-aira-btn class="note-main__right-sec--item"> </app-aira-btn> -->
  <div class="note-main__right-sec--item">
    <!-- <button mat-flat-button class="button-secondary">Disclaimer</button> -->
  </div>
  <div class="note-main__right-sec--item">
    <!-- <button mat-flat-button class="button-secondary">New Note</button> -->
  </div>
  <div>
    <!-- <button
      [ngClass]="{
        'button-secondary white': !!augnitoService.augnitoBtnActive,
        'button-primary': !augnitoService.augnitoBtnActive
      }"
      cpAugnitoButton
      (micOffDialog)="augnitoService.micStatus($event)"
      (augnitoText)="augnitoService.augnitoText($event)"
      (partialText)="augnitoService.partialTextAugnitochk($event)"
      mat-flat-button
    >
      <img
        [src]="
          !augnitoService.augnitoBtnActive
            ? '../../../../assets/icons/swara.svg'
            : '../../../../assets/images/google-mic-seeklogo.com.svg'
        "
        alt="mic"
      />swaᴙa
    </button> -->
  </div>
</div>
<div class="note-main--hyper-text">
  <!-- <div *ngIf="augnitoService.hyperText.show" id="hyperText" class="col-sm-12">
    {{ augnitoService.hyperText.val }}
  </div> -->
  <!-- <div
    *ngIf="augnitoService.cursorIndex"
    class="note-main__right-sec__branding"
  >
    swaᴙa<mat-divider vertical></mat-divider>Powered by Augnito.
  </div> -->
</div>
<div [formGroup]="patientNotesForm" class="cp-flex-container">
  <div class="note-edit-addendum">
    <ng-container
      *ngFor="let componentControl of componentsFormArray; let i = index"
    >
      <div [innerHtml]="componentControl.value.value"></div>
    </ng-container>
    <div *ngIf="getMergeNotes?.length" class="note-edit-addendum__section">
      <ng-container *ngFor="let note of getMergeNotes">
        <mat-divider></mat-divider>
        <div class="note-edit-addendum__section__heading">
          <div>{{ note.name }}</div>
        </div>
        <div [innerHtml]="note.note"></div>
      </ng-container>
    </div>
  </div>

  <div class="note-edit">
    <div class="editor__heading">
      <div>Addendum</div>
    </div>
    <div class="editor">
      <div id="quillAddendum" class="editor__quill">
        <cp-quill-editor
          #editor
          (onContentChanged)="augnitoService.contentChanged($event)"
          [formControl]="addendumNote"
        ></cp-quill-editor>
      </div>
    </div>
  </div>
</div>
<cp-note-action
  [patientNotesForm]="patientNotesForm"
  [addendumNote]="addendumNote"
></cp-note-action>
